<template lang="">
    <div>
        <Offer/>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ön Teklifler", route: "/lists/offer" },
    ]);
  },
    components: {
    Offer: () => import('@/components/lists/Offer.vue'),
}
}
</script>
<style lang="">
    
</style>